import './ObjectEditor.css';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import getLanguage from '../../../core/Language';
import { TemplateService } from '../../templates/services/TemplateService';
import { ToastsStore } from 'react-toasts';
import { LoginHelper } from '../../../core/LoginHelper';
import { makeStyles } from '@material-ui/core/styles';
import { validateEmail } from '../../../core/helpers/StringHelper';
import orderBy from 'lodash/orderBy';
import MultiSelector from '../../../core/components/MultiSelector';
import { PageService } from '../services/PageService';

const useStyles = makeStyles((theme) => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
}));

const EditPageDetails = (props) => {
  const classes = useStyles();

  const templateService = new TemplateService();
  const loginHelper = new LoginHelper();
  const pageService = new PageService();

  const [templates, setTemplates] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [id, setId] = useState('');
  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [titleError, setTitleError] = useState('');
  const [templateError, setTemplateError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [externalUrl, setExternalUrl] = useState('');
  const [isPremium, setIsPremium] = useState(false);
  const [canContainEvents, setCanContainEvents] = useState(false);
  const [useInPrograms, setUseInPrograms] = useState(false);
  const [canBeArchive, setCanBeArchive] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [description, setDescription] = useState('');
  const [superAdminId, setSuperAdminId] = useState('');
  const [navHide, setNavHide] = useState(false);
  const [showNav, setShowNav] = useState(true);
  const [allowMulti, setAllowMulti] = useState(false);
  const [requirePaid, setRequirePaid] = useState(false);
  const [canContainPopupMessages, setCanContainPopupMessages] = useState(false);
  const [sortingKey, setSortingKey] = useState(0);
  const [showNewsticker, setShowNewsticker] = useState(false);
  const [enablePrivateChat, setEnablePrivateChat] = useState(props.isStand);
  const [enablePublicChat, setEnablePublicChat] = useState(false);
  const [exhibitionHallPages, setExhibitionHallPages] = useState([]);
  const [selectedExhibitionHalls, setSelectedExhibitionHalls] = useState([]);
  const [showSplashScreen, setShowSplashScreen] = useState(false);
  const [showSplashScreenOnce, setShowSplashScreenOnce] = useState(false);
  const [splashScreenUrl, setSplashScreenUrl] = useState('');

  useEffect(() => {
    if (props.page) {
      setId(props.page.id);
      setTitle(props.page.title);
      setExternalUrl(props.page.externalUrl);
      setIsPremium(props.page.isPremium);
      setCanContainEvents(props.page.canContainEvents);
      setUseInPrograms(props.page.useInPrograms);
      setCanBeArchive(props.page.canBeArchive);
      setCompanyName(props.page.companyName);
      setDescription(props.page.description);
      setSuperAdminId(props.page.superAdminId);
      setNavHide(props.page.navHide);
      setShowNav(props.page.showNav);
      setAllowMulti(props.page.allowMulti);
      setRequirePaid(props.page.requirePaid);
      setCanContainPopupMessages(!props.page.canContainPopupMessages);
      setSortingKey(props.page.sortingKey);
      setShowNewsticker(props.page.showNewsticker);
      setEnablePrivateChat(props.page.enablePrivateChat);
      setEnablePublicChat(props.page.enablePublicChat);
      setShowSplashScreen(props.page.showSplashScreen);
      setSplashScreenUrl(props.page.splashScreenUrl);
      setShowSplashScreenOnce(props.page.showSplashScreenOnce);
    }

    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    let response = await templateService.getTemplatesForHive(
      props.page?.hiveId ?? null
    );

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      if (props.isStand) {
        response = response.filter((t) => t.kind === 'ExhibitionStand');
      } else {
        response = response.filter((t) => t.kind !== 'ExhibitionStand');
      }

      response = orderBy(response, ['title'], ['asc']);

      setTemplates(response);
      fetchExhibitionHallPages();
    }
  };

  const customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (row[field] && isNaN(row[field])) {
        return row[field].toLowerCase();
      }

      return row[field];
    };

    return orderBy(rows, handleField, direction);
  };

  const fetchExhibitionHallPages = async () => {
    let response = await pageService.getPages(loginHelper.getCurrentHive().id);

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      response = response.filter((t) => t.template.kind === 'ExhibitionHall');

      response = customSort(response, ['title'], ['asc']);

      let exhibitionHalls = [];

      if (props.page?.id) {
        if (props.page?.presentOnPageIds) {
          for (const item of props.page.presentOnPageIds.split(';')) {
            let page = response.find((t) => t.id === item);
            if (page) {
              exhibitionHalls.push(page);
            }
          }
        }
      } else {
        if (response && response.length === 1) {
          exhibitionHalls.push(response[0]);
        }
      }

      setSelectedExhibitionHalls(exhibitionHalls);

      setExhibitionHallPages(response);
    }
  };

  const onSaveClicked = async () => {
    var validationError = false;

    // Validate title not empty
    if (!title) {
      setTitleError(getLanguage('ValidationErrorCannotBeEmpty'));
      validationError = true;
    } else {
      setTitleError('');
    }

    // Validate template selected
    if (!id && !selectedTemplateId) {
      setTemplateError(getLanguage('ValidationErrorCannotBeEmpty'));
      validationError = true;
    } else {
      setTemplateError('');
    }

    // Validate e-mail valid (if not empty)
    if (!id && email && !validateEmail(email)) {
      setEmailError(getLanguage('ValidationErrorInvalidEmail'));
      validationError = true;
    } else {
      setEmailError('');
    }

    if (validationError) {
      return;
    }

    let presentOnPageIds = '';
    if (props.isStand) {
      for (const page of selectedExhibitionHalls) {
        presentOnPageIds += page.id + ';';
      }
    }

    if (id) {
      props.onSave(
        {
          showSplashScreen,
          showSplashScreenOnce,
          splashScreenUrl,
          title,
          isPremium,
          externalUrl,
          canContainEvents,
          canBeArchive,
          useInPrograms,
          companyName,
          description,
          superAdminId,
          navHide,
          showNav,
          allowMulti,
          requirePaid,
          canContainPopupMessages: !canContainPopupMessages,
          sortingKey,
          showNewsticker,
          enablePrivateChat,
          enablePublicChat,
          presentOnPageIds,
        },
        null
      );
    } else {
      props.onSave(
        {
          hiveId: loginHelper.getCurrentHive().id,
          templateId: selectedTemplateId,
          showSplashScreen,
          showSplashScreenOnce,
          splashScreenUrl,
          title,
          isPremium,
          externalUrl,
          canContainEvents,
          canBeArchive,
          useInPrograms,
          companyName,
          description,
          superAdminId,
          navHide,
          showNav,
          allowMulti,
          requirePaid,
          canContainPopupMessages: !canContainPopupMessages,
          sortingKey,
          showNewsticker,
          enablePrivateChat,
          enablePublicChat,
          presentOnPageIds,
        },
        email
      );
    }
  };

  const getTemplateOptions = () => {
    return templates.map((data) => (
      <MenuItem key={data.id} value={data.id}>
        {data.title}
      </MenuItem>
    ));
  };

  const handleTemplateChanged = (event) => {
    setSelectedTemplateId(event.target.value);
    setTemplateError('');
  };

  const attemptUpdateAttribute = (attribute, newValue) => {
    if (props.updateAttribute) {
      props.updateAttribute(attribute, newValue);
    }
  };

  const onSelectExhibitionHalls = (pages) => {
    let presentOnPageIds = '';
    if (props.isStand) {
      for (const page of pages) {
        presentOnPageIds += page.id + ';';
      }
    }

    setSelectedExhibitionHalls(pages);
    attemptUpdateAttribute('presentOnPageIds', presentOnPageIds);
  };

  const onRemoveExhibitionHalls = (pages) => {
    let presentOnPageIds = '';
    if (props.isStand) {
      for (const page of pages) {
        presentOnPageIds += page.id + ';';
      }
    }

    setSelectedExhibitionHalls(pages);
    attemptUpdateAttribute('presentOnPageIds', presentOnPageIds);
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSaveClicked();
        }}
      >
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <TextField
            variant='outlined'
            error={titleError}
            helperText={titleError}
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
              setTitleError('');
              attemptUpdateAttribute('title', e.target.value);
            }}
            label={getLanguage('Title')}
            className='textField'
          />
        </FormControl>
        {props.isStand && (
          <FormControl
            fullWidth={true}
            variant='outlined'
            className={classes.formControl}
          >
            <TextField
              variant='outlined'
              value={companyName}
              onChange={(e) => {
                setCompanyName(e.target.value);
                attemptUpdateAttribute('companyName', e.target.value);
              }}
              label={getLanguage('CompanyName')}
              className='textField'
            />
          </FormControl>
        )}
        <FormControl
            fullWidth={true}
            variant='outlined'
            className={classes.formControl}
         >
           <textarea
             variant='outlined'
             value={description}
             onChange={(e) => {
               setDescription(e.target.value);
               attemptUpdateAttribute('description', e.target.value);
             }}
             label={getLanguage('StandDescription')}
             rows='6'
             className='textArea'
           />
        </FormControl>
        {props.isStand && (
          <>
            <MultiSelector
              displayValue='title'
              placeholder={getLanguage('PresentOnExhibitionHalls')}
              items={exhibitionHallPages}
              selectedValues={selectedExhibitionHalls}
              onSelect={onSelectExhibitionHalls}
              onRemove={onRemoveExhibitionHalls}
            />
          </>
        )}

        {(loginHelper.userHasRole('PageEditor') ||
          loginHelper.userHasRole('StandEditor')) && (
          <>
            {props.isStand && (
              <>
                <FormControl
                  fullWidth={true}
                  variant='outlined'
                  className={classes.formControl}
                >
                  <TextField
                    variant='outlined'
                    value={externalUrl}
                    onChange={(e) => {
                      setExternalUrl(e.target.value);
                      attemptUpdateAttribute('externalUrl', e.target.value);
                    }}
                    label={getLanguage('GoToUrlInsteadOfStand')}
                    className='textField'
                  />
                </FormControl>
                {/* <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
                            <FormControlLabel
                                control={<Checkbox color="primary" checked={isPremium} onChange={(e) => { setIsPremium(e.target.checked); attemptUpdateAttribute("isPremium", e.target.checked); }} />}
                                label={getLanguage("Premium")}
                                className="checkbox"
                            />
                        </FormControl> */}
              </>
            )}
            {/* <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={requirePaid} onChange={(e) => { setRequirePaid(e.target.checked); attemptUpdateAttribute("requirePaid", e.target.checked); }} />}
                            label={getLanguage("AccessRequiresPayment")}
                            className="checkbox"
                        />
                    </FormControl> */}

            {props.isStand && (
              <>
                <FormControl
                  fullWidth={true}
                  variant='outlined'
                  className={classes.formControl}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        color='primary'
                        checked={enablePrivateChat}
                        onChange={(e) => {
                          setEnablePrivateChat(e.target.checked);
                          attemptUpdateAttribute(
                            'enablePrivateChat',
                            e.target.checked
                          );
                        }}
                      />
                    }
                    label={getLanguage(
                      'AllowVisitorsToContactYouIfYoureOnline'
                    )}
                    className='checkbox'
                  />
                </FormControl>
              </>
            )}

            {/* {!props.isStand && <>
                        <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
                            <FormControlLabel
                                control={<Checkbox color="primary" checked={enablePublicChat} onChange={(e) => { setEnablePublicChat(e.target.checked); attemptUpdateAttribute("enablePublicChat", e.target.checked); }} />}
                                label={getLanguage("EnablePublicChatForThePage")}
                                className="checkbox"
                            />
                        </FormControl>
                    </>} */}

            <p>
              <b>{getLanguage('DisplayInSelectionBoxFor')}</b>
            </p>
            <div style={{ marginLeft: '20px' }}>
              <FormControl
                fullWidth={true}
                variant='outlined'
                className={classes.formControl}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      color='primary'
                      checked={canContainEvents}
                      onChange={(e) => {
                        setCanContainEvents(e.target.checked);
                        attemptUpdateAttribute(
                          'canContainEvents',
                          e.target.checked
                        );
                      }}
                    />
                  }
                  label={getLanguage('DisplayInProgramEditor')}
                  className='checkbox'
                />
              </FormControl>
            </div>
            {!props.isStand && (
              <FormControl
                fullWidth={true}
                variant='outlined'
                className={classes.formControl}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      color='primary'
                      checked={canContainPopupMessages}
                      onChange={(e) => {
                        setCanContainPopupMessages(e.target.checked);
                        attemptUpdateAttribute(
                          'canContainPopupMessages',
                          !e.target.checked
                        );
                      }}
                    />
                  }
                  label={getLanguage('CanContainPopupMessages')}
                  className='checkbox'
                />
              </FormControl>
            )}
            {loginHelper.isAdmin() && !props.isStand && (
              <FormControl
                fullWidth={true}
                variant='outlined'
                className={classes.formControl}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      color='primary'
                      checked={navHide}
                      onChange={(e) => {
                        setNavHide(e.target.checked);
                        attemptUpdateAttribute('navHide', e.target.checked);
                      }}
                    />
                  }
                  label={getLanguage('HideInBreadcrumb')}
                  className='checkbox'
                />
              </FormControl>
            )}
            {loginHelper.isAdmin() && !props.isStand && (
              <FormControl
                fullWidth={true}
                variant='outlined'
                className={classes.formControl}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      color='primary'
                      checked={showNav}
                      onChange={(e) => {
                        setShowNav(e.target.checked);
                        attemptUpdateAttribute('showNav', e.target.checked);
                      }}
                    />
                  }
                  label={getLanguage('ShowPageNavigation')}
                  className='checkbox'
                />
              </FormControl>
            )}
            {loginHelper.isAdmin() && !props.isStand && (
              <FormControl
                fullWidth={true}
                variant='outlined'
                className={classes.formControl}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      color='primary'
                      checked={allowMulti}
                      onChange={(e) => {
                        setAllowMulti(e.target.checked);
                        attemptUpdateAttribute('allowMulti', e.target.checked);
                      }}
                    />
                  }
                  label={getLanguage('AllowMultipleTimesInNavigation')}
                  className='checkbox'
                />
              </FormControl>
            )}

            {!props.isStand && (
              <FormControl
                fullWidth={true}
                variant='outlined'
                className={classes.formControl}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      color='primary'
                      checked={showNewsticker}
                      onChange={(e) => {
                        setShowNewsticker(e.target.checked);
                        attemptUpdateAttribute(
                          'showNewsticker',
                          e.target.checked
                        );
                      }}
                    />
                  }
                  label={getLanguage('ShowNewsticker')}
                  className='checkbox'
                />
              </FormControl>
            )}
            <FormControl
              fullWidth={true}
              variant='outlined'
              className={classes.formControl}
            >
              <TextField
                variant='outlined'
                value={sortingKey}
                onChange={(e) => {
                  setSortingKey(e.target.value);
                  attemptUpdateAttribute('sortingKey', e.target.value);
                }}
                label={getLanguage('SortingKey')}
                type='number'
                className='textField'
              />
            </FormControl>
            <FormControl
              fullWidth={true}
              variant='outlined'
              className={classes.formControl}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    checked={showSplashScreen}
                    onChange={(e) => {
                      setShowSplashScreen(e.target.checked);
                      attemptUpdateAttribute(
                        'showSplashScreen',
                        e.target.checked,
                      )
                    }}
                  />
                }
                label='Show splash screen'
                className='checkbox'
              />
              <div style={{ marginLeft: '20px' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color='primary'
                      checked={showSplashScreenOnce}
                      onChange={(e) => {
                        setShowSplashScreenOnce(e.target.checked);
                        attemptUpdateAttribute(
                          'showSplashScreenOnce',
                          e.target.checked,
                        )
                      }}
                    />
                  }
                  label='Show once'
                  className='checkbox'
                />
                <br />
                <TextField
                  variant='outlined'
                  value={splashScreenUrl}
                  onChange={(e) => {
                    setSplashScreenUrl(e.target.value)
                    attemptUpdateAttribute('splashScreenUrl', e.target.value)
                  }}
                  label='Splash screen url'
                  type='string'
                  className='textField'
                />
              </div>
            </FormControl>
          </>
        )}
        {!id && (
          <>
            <div className={classes.selectContainer}>
              <FormControl
                helperText={templateError}
                fullWidth={true}
                variant='outlined'
                className={classes.formControl}
              >
                <InputLabel
                  className={classes.selectLabel}
                  id='templateSelector-label'
                >
                  {getLanguage('PageType')}
                </InputLabel>
                <Select
                  error={templateError}
                  variant='outlined'
                  fullWidth={true}
                  value={selectedTemplateId}
                  onChange={handleTemplateChanged}
                  labelId='templateSelector-label'
                  id='templateSelector'
                >
                  <MenuItem value={''}>
                    <em>{getLanguage('PleaseChoose')}</em>
                  </MenuItem>
                  {getTemplateOptions()}
                </Select>
                {templateError && (
                  <p className='MuiFormHelperText-root MuiFormHelperText-contained Mui-error'>
                    {templateError}
                  </p>
                )}
              </FormControl>
            </div>
          </>
        )}
        {!id && (
          <>
            <FormControl
              fullWidth={true}
              variant='outlined'
              className={classes.formControl}
            >
              <TextField
                variant='outlined'
                error={emailError}
                helperText={emailError}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError('');
                }}
                label={getLanguage('InviteWithEmail')}
                className='textField'
              />
            </FormControl>
          </>
        )}

        {!id && (
          <Button
            type='submit'
            fullWidth={true}
            variant='contained'
            color='primary'
          >
            {getLanguage('Save')}
          </Button>
        )}
      </form>
    </>
  );
};

export default EditPageDetails;
